export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Krupa Writes',
  description: 'The World Has So Much !!',
  coverImage: 'img/blog-cover.jpg',
  logo: 'avatars/avatar.jpg',
  lang: 'en',
  siteUrl: 'https://www.krupawrites.com',
  facebook: 'https://www.facebook.com/krupawrites1',
  twitter: 'https://twitter.com/krupak1926',
  showSubscribe: false,
  mailchimpAction: 'https://krupawrites.us7.list-manage.com/subscribe/post?u=0284b9244d29061dfb78c030d&amp;id=f814d39e26',
  mailchimpName: 'b_0284b9244d29061dfb78c030d_f814d39e26',
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'GoogleCode',
  footer: 'is based on Gatsby Casper inspired by Casper template from Ghost',
};

export default config;
